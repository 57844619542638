/* eslint-disable arrow-parens */
/* eslint-disable semi */
/* eslint-disable quotes */
import store from "@/store";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "dashboard",
      component: () => import("@/views/dashboard/Dashboard.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Dashboard",
        breadcrumb: [
          {
            text: "Dashboard",
            active: true,
          },
        ],
      },
    },
    {
      path: "/change-password",
      name: "change-password",
      component: () => import("@/views/PasswordChange.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Password",
        breadcrumb: [
          {
            text: "Change Password",
            active: true,
          },
        ],
      },
    },
    {
      path: "/certificate",
      name: "certificate",
      component: () => import("@/views/certificate/CertificateList.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Certificates",
        breadcrumb: [
          {
            text: "Certificates",
            active: true,
          },
        ],
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        visitor: true,
        layout: "full",
      },
    },
    {
      path: "/role",
      name: "role",
      component: () => import("@/views/role/RoleManage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Role Manage",
        breadcrumb: [
          {
            text: "Role Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/certificate/background",
      name: "certificate-background",
      component: () => import("@/views/background/CertificateBackground.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Certificate Background",
        breadcrumb: [
          {
            text: "Certificate Background",
            active: true,
          },
        ],
      },
    },
    {
      path: "/certificate/format",
      name: "certificate-format",
      component: () => import("@/views/template/CertificateFormat.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Certificate Format",
        breadcrumb: [
          {
            text: "Certificate Format",
            active: true,
          },
        ],
      },
    },
    {
      path: "/certificate/program",
      name: "certificate-program",
      component: () => import("@/views/program/Program.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Certificate Program",
        breadcrumb: [
          {
            text: "Certificate Program",
            active: true,
          },
        ],
      },
    },
    {
      path: "/certificate/single-program/:program_id",
      name: "certificate-single-program",
      component: () => import("@/views/program/SingleProgram.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Program",
        breadcrumb: [
          {
            text: "Program",
            active: true,
          },
        ],
      },
    },
    {
      path: "/certificate/generator/:format_id",
      name: "certificate-generator",
      component: () => import("@/views/template/CertificateGenerator.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Certificate Template Generator",
        breadcrumb: [
          {
            text: "Certificate Template Generator",
            active: true,
          },
        ],
      },
    },
    {
      path: "/single/certificate/format/:format_id",
      name: "single-certificate-format",
      component: () => import("@/views/template/SingleCertificateFormat.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Single Certificate Format",
        breadcrumb: [
          {
            text: "Single Certificate Format",
            active: true,
          },
        ],
      },
    },
    {
      path: "/meta/data",
      name: "meta-data",
      component: () => import("@/views/metadata/MetaData.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Meta Data",
        breadcrumb: [
          {
            text: "Meta Data",
            active: true,
          },
        ],
      },
    },
    {
      path: "/user",
      name: "user",
      component: () => import("@/views/User/User.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "User",
        breadcrumb: [
          {
            text: "Dashboard",
            to: "/dashboard",
          },
          {
            text: "User",
            active: true,
          },
        ],
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        visitor: true,
        layout: "full",
      },
    },

    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  if (localStorage.getItem("token")) {
    store.dispatch("CheckAuth");
  }
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiredAuth)) {
    if (!store.getters.loggedIn) {
      next({ name: "login" });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.visitor)) {
    if (store.getters.loggedIn) {
      next({ name: "dashboard" });
    } else {
      next();
    }
  }
});

export default router;
