export default {
  state: {},
  getters: {},
  actions: {
    getPermissions(content) {
      axios
        .get("/app/permission")
        .then((res) => {
          let permissions = {
            //User Permission here
            user: {
              access: false,
              create: false,
              edit: false,
              delete: false,
            },

            //Certificate Permission
            certificate: {
              access: false,
            },
            background: {
              access: false,
              create: false,
              edit: false,
              update: false,
            },
            format: {
              access: false,
              create: false,
              edit: false,
              update_status: false,
              attach_manage: false,
            },
            metadata: {
              access: false,
              create: false,
              edit: false,
              delete: false,
            },
            role: {
              access: false,
              create: false,
              edit: false,
              delete: false,
            },

            //Programs
            program: {
              access: false,
              create: false,
              edit: false,
            },
          };
          res.data.permissions.forEach((permission) => {

            //Certificate here
            if (permission.slug == "certificate.access") {
              permissions.certificate.access = true;
            }

            //role here
            if (permission.slug == "role.access") {
              permissions.role.access = true;
            }
            if (permission.slug == "role.create") {
              permissions.role.create = true;
            }
            if (permission.slug == "role.edit") {
              permissions.role.edit = true;
            }
            if (permission.slug == "role.destroy") {
              permissions.role.delete = true;
            }

            //certificate access
            if (permission.slug == "certificate.background.access") {
              permissions.background.access = true;
            }

            if (permission.slug == "certificate.background.create") {
              permissions.background.create = true;
            }

            if (permission.slug == "certificate.background.edit") {
              permissions.background.edit = true;
            }

            if (permission.slug == "certificate.background.status.update") {
              permissions.background.update = true;
            }

            //certificate Format
            if (permission.slug == "certificate.format.access") {
              permissions.format.access = true;
            }

            if (permission.slug == "certificate.format.create") {
              permissions.format.create = true;
            }

            if (permission.slug == "certificate.format.edit") {
              permissions.format.edit = true;
            }

            if (permission.slug == "certificate.format.update.status") {
              permissions.format.update_status = true;
            }

            if (permission.slug == "certificate.meta.data.attach.manage") {
              permissions.format.attach_manage = true;
            }
            //User Permission here
            if (permission.slug == "user.access") {
              permissions.user.access = true;
            }
            if (permission.slug == "user.create") {
              permissions.user.create = true;
            }
            if (permission.slug == "user.edit") {
              permissions.user.edit = true;
            }
            if (permission.slug == "user.destroy") {
              permissions.user.delete = true;
            }

            //metadata Permission here
            if (permission.slug == "certificate.meta.data.access") {
              permissions.metadata.access = true;
            }
            if (permission.slug == "certificate.meta.data.create") {
              permissions.metadata.create = true;
            }
            if (permission.slug == "certificate.meta.data.edit") {
              permissions.metadata.edit = true;
            }
            if (permission.slug == "certificate.meta.data.destroy") {
              permissions.metadata.delete = true;
            }

            //Program Permission here
            if (permission.slug == "program.access") {
              permissions.program.access = true;
            }
            if (permission.slug == "program.create") {
              permissions.program.create = true;
            }
            if (permission.slug == "program.edit") {
              permissions.program.edit = true;
            }
          });
          content.commit("getPermissions", permissions);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
