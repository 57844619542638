import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import permission from "@/store/permission";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        base_url: "https://certificate.citsmp.com/",
        token: localStorage.getItem("token") || null,
        user_name: localStorage.getItem("user_name") || null,
        email: localStorage.getItem("email") || null,
        phone: localStorage.getItem("phone") || null,
        photo: localStorage.getItem("photo") || null,
        getPermissions: {},
        getDivision: {},
        getDistrict: {},
        getActiveBackground: {},
        getUpazila: {},
        getAllDistrict: {},
    },
    actions: {
        removeToken(context) {
            context.commit("removeToken");
        },
        setToken(context, data) {
            context.commit("setToken", data);
        },
        CheckAuth(content) {
            axios.defaults.headers.common["Authorization"] =
                "Bearer " + localStorage.getItem("token");
            axios
                .get("/check")
                .then((res) => {
                    if (!res.data.status) {
                        localStorage.removeItem("token");
                        content.commit("removeToken");
                        router.push("/login");
                    }
                })
                .catch((e) => {
                    if (e.response.status === 401) {
                        localStorage.removeItem("token");
                        content.commit("removeToken");
                        router.push("/login");
                    }
                });
        },
        getActiveBackground(content) {
            axios
               .get("/app/get/all/certificate/background")
               .then((res) => {
                   content.commit("getActiveBackground", res.data.backgrounds);
               })
               .catch((e) => {
                   this.e(e.data.error);
               });
        },
    },
    getters: {
        getActiveBackground(state) {
            return state.getActiveBackground;
        },
        loggedIn(state) {
            return state.token !== null;
        },
        getPermissions(state) {
            return state.getPermissions;
        },
    },
    mutations: {
        setToken(state, data) {
            state.token = data.token;
            state.user_name = data.name;
            state.email = data.email;
            state.phone = data.phone;
            state.photo = data.photo;
            state.onboard = data.onboard_status;
        },
        removeToken(state) {
            state.token = null;
            state.user_name = null;
            state.email = null;
            state.phone = null;
            state.photo = null;
            state.onboard = null;
        },
        getPermissions(state, data) {
            return (state.getPermissions = data);
        },
        getActiveBackground(state, data) {
            return (state.getActiveBackground = data);
        },
    },
    modules: {
        app,
        appConfig,
        verticalMenu,
        permission,
    },
    strict: process.env.DEV,
});
